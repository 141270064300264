import {
  ChartContext,
  IChartContext,
} from '../../components/chart-home/ChartHome';
import { useContext, useEffect, useState } from 'react';
import { MermaidDiv } from './chart-styles';
import mermaid from 'mermaid';
// import databaseService from '../../services/database-service';
import { chartConfig } from './config';

const Chart = () => {
  const { markdown } = useContext(ChartContext) as IChartContext;
  const [markdownFormatted, setMarkdownFormatted] = useState('');

  // const selectNode = async (nodeNumber: string): Promise<void> => {
  //   const nodeId = Number(nodeNumber.replace('n', ''));
  //   const newMarkdown = await databaseService.getMarkdown(nodeId);
  //   markdownGenerated(newMarkdown);
  // };

  useEffect(() => {
    const getMarkdownFormatted = () => {
      return markdown.replaceAll('<br>', '\n');
    };
    mermaid.initialize({
      startOnLoad: true,
      securityLevel: chartConfig.securityLevel.Loose,
      theme: chartConfig.theme.Default,
    });
    const formatted = getMarkdownFormatted();
    (async () => {
      const { svg } = await mermaid.render('chart1', formatted);
      setMarkdownFormatted(svg);
    })();

    // setTimeout(() => {
    //   const nodes = document.querySelectorAll('*[id^="flowchart-n"]');
    //   nodes.forEach((node: Element) => {
    //     if (!node.id) return;
    //     const id = node.id;
    //     const startStr = 'flowchart-';
    //     const endStr = '-';
    //     const nodeNumber = id.substring(startStr.length, id.lastIndexOf(endStr));

    //     const clickable = node as HTMLElement;
    //     clickable.onclick = () => {
    //       selectNode(nodeNumber);
    //     };
    //   });
    // }, 500);
  }, [markdown]);

  return (
    <>
      {markdownFormatted && (
        <MermaidDiv
          className="mermaid"
          dangerouslySetInnerHTML={{ __html: markdownFormatted }}
        ></MermaidDiv>
      )}
    </>
  );
};

export default Chart;
