import { useContext, useEffect, useState } from 'react';
import DatabaseService from '../../services/database-service';
import { Entity } from '@/model/Entity';
import {
  ControlsTop,
  Container,
} from '../chart-controls/chart-controls-styles';
import Select from '@mui/material/Select';
import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { ChartContext, IChartContext } from './column-lineage-page';

interface EntitySelection {
  label: string;
  value: number;
}

interface Selection {
  id?: number;
  name: string;
}

const ColumnLineageChartControls = () => {
  const entityDefault = {
    label: '',
    value: 0,
  };
  const selectionDefault = {
    id: undefined,
    name: '',
  };
  const { lineageGenerated } = useContext(ChartContext) as IChartContext;
  const [catalogs, setCatalogs] = useState<EntitySelection[]>([entityDefault]);
  const [schemas, setSchemas] = useState<EntitySelection[]>([]);
  const [tables, setTables] = useState<EntitySelection[]>([]);
  const [columns, setColumns] = useState<EntitySelection[]>([]);
  const [selectedCatalog, setSelectedCatalog] =
    useState<Selection>(selectionDefault);
  const [selectedSchema, setSelectedSchema] =
    useState<Selection>(selectionDefault);
  const [selectedTable, setSelectedTable] =
    useState<Selection>(selectionDefault);
  const [selectedColumn, setSelectedColumn] =
    useState<Selection>(selectionDefault);

  useEffect(() => {
    const getData = async () => {
      const catalogData = await DatabaseService.getCatalogs();
      const catalogsFormatted = catalogData.map((x: Entity) => ({
        label: x.name,
        value: x.id,
      }));
      setCatalogs(catalogsFormatted);
    };

    getData().catch(console.error);
  }, []);

  const catalogChanged = async (event: SelectChangeEvent) => {
    setSelectedSchema(selectionDefault);
    setSchemas([]);
    const catalog = catalogs.find((x) => x.value === +event.target.value);
    if (!catalog) return;
    setSelectedCatalog({ id: catalog.value, name: catalog.label });

    const schemaData = await DatabaseService.getSchemas(catalog.value);
    const dataFormatted = schemaData.map((x: Entity) => ({
      label: x.name,
      value: x.id,
    }));
    setSchemas(dataFormatted);
  };

  const schemaChanged = async (event: SelectChangeEvent) => {
    setSelectedTable(selectionDefault);
    setTables([]);
    const schema = schemas.find((x) => x.value === +event.target.value);
    if (!schema) return;
    setSelectedSchema({ id: schema.value, name: schema.label });

    const tableData = await DatabaseService.getTables(schema.value);
    const dataFormatted = tableData.map((x: Entity) => ({
      label: x.name,
      value: x.id,
    }));
    setTables(dataFormatted);
  };

  const tableChanged = async (event: SelectChangeEvent) => {
    setSelectedColumn(selectionDefault);
    setColumns([]);
    const table = tables.find((x) => x.value === +event.target.value);
    if (!table) return;
    setSelectedTable({ id: table.value, name: table.label });

    const columnData = await DatabaseService.getColumns(table.value);
    const dataFormatted = columnData.map((x: Entity) => ({
      label: x.name,
      value: x.id,
    }));
    setColumns(dataFormatted);
  };

  const columnChanged = async (event: SelectChangeEvent) => {
    const column = columns.find((x) => x.value === +event.target.value);
    if (!column) return;
    setSelectedColumn({ id: column.value, name: column.label });

    const md = await DatabaseService.getColumnLineage(column.value);
    lineageGenerated(md);
  };

  return (
    <>
      <Container>
        <ControlsTop>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="catalog-select-label">Catalog</InputLabel>
            <Select
              labelId="catalog-select-label"
              id="catalog-select"
              value={selectedCatalog.id?.toString() || ''}
              onChange={catalogChanged}
            >
              {catalogs?.length &&
                catalogs.map((x, i) => (
                  <MenuItem key={i} value={x.value}>
                    {x.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="schema-select-label">Schema</InputLabel>
            {!!schemas?.length && (
              <Select
                labelId="schema-select-label"
                id="schema-select"
                value={selectedSchema.id?.toString() || ''}
                onChange={schemaChanged}
              >
                {schemas?.length &&
                  schemas.map((x, i) => (
                    <MenuItem key={i} value={x.value}>
                      {x.label}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="table-select-label">Table</InputLabel>
            {!!tables?.length && (
              <Select
                labelId="table-select-label"
                id="table-select"
                value={selectedTable.id?.toString() || ''}
                onChange={tableChanged}
              >
                {tables?.length &&
                  tables.map((x, i) => (
                    <MenuItem key={i} value={x.value}>
                      {x.label}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="column-select-label">Column</InputLabel>
            {!!columns?.length && (
              <Select
                labelId="column-select-label"
                id="column-select"
                value={selectedColumn.id?.toString() || ''}
                onChange={columnChanged}
              >
                {columns?.length &&
                  columns.map((x, i) => (
                    <MenuItem key={i} value={x.value}>
                      {x.label}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
        </ControlsTop>
      </Container>
    </>
  );
};

export default ColumnLineageChartControls;
