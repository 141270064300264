'use client';
import '@xyflow/react/dist/base.css';
import { useContext } from 'react';
import { Background, ReactFlow } from '@xyflow/react';
import { DbTransformation, DbTable } from './database-schema-node';
import { ChartContext, IChartContext } from './column-lineage-page';

const nodeTypes = {
  dbTable: DbTable,
  dbTransformation: DbTransformation,
};

const ColumnLineageChart = () => {
  const { lineage_data } = useContext(ChartContext) as IChartContext;
  // TODO - settle on a styling framework - Tailwind or Styled Components.
  return (
    <div style={{ width: '1000px', height: '900px' }}>
      <ReactFlow
        defaultNodes={lineage_data.defaultNodes}
        defaultEdges={lineage_data.defaultEdges}
        nodeTypes={nodeTypes}
        fitView
      >
        <Background />
      </ReactFlow>
    </div>
  );
};

export default ColumnLineageChart;
