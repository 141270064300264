import { HeadingLg } from '../../shared/styles';

const Home = () => {
  return (
    <>
      <HeadingLg>welcome to Metacetera!</HeadingLg>
    </>
  );
};

export default Home;
