import { FileParseOutcome } from '@/model/FileParseOutcome';
import { formatDate } from '../../Utils';

export type OutcomeItemProps = {
  outcome: FileParseOutcome;
};

const OutcomeItem: React.FC<OutcomeItemProps> = (props) => {
  const { _id, filename, file_path, parse_status, neo_writer_stats } =
    props.outcome;

  return (
    <>
      <div>{_id}</div>
      <div>{filename}</div>
      <div>{file_path}</div>
      <div>{parse_status ? 'Success' : 'Fail'}</div>
      <div>
        {neo_writer_stats?.completed_at
          ? formatDate(neo_writer_stats?.completed_at)
          : ''}
      </div>
      <div>{neo_writer_stats?.elapsed.write_ms}</div>
      <div>{neo_writer_stats?.errors}</div>
    </>
  );
};

export { OutcomeItem };
