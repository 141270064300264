import { Route, Routes } from 'react-router-dom';
import { ChartHome, Home, SchemaUpload, Settings } from '.';
import ViewScript from './view-script/ViewScript';
import FileParseOutcomes from './file-parse-outcomes/FileParseOutcomes';
import ColumnLineagePage from './column-level-lineage/column-lineage-page';

const RouteWrapper = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/chart" element={<ChartHome />} />
        <Route path="/new-project" element={<SchemaUpload />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/loginredirect" element={<Home />} />
        <Route path="/view-script" element={<ViewScript />} />
        <Route path="/parse-outcome" element={<FileParseOutcomes />} />
        <Route path="/column-level-lineage" element={<ColumnLineagePage />} />
      </Routes>
    </>
  );
};

export default RouteWrapper;
