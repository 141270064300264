import { Project } from '../../model/Project';
import databaseService from '../../services/database-service';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';

export type ProjectSelectProps = {
  collectionName: string;
  setCollectionName: React.Dispatch<React.SetStateAction<string>>;
};

const ProjectSelect: React.FC<ProjectSelectProps> = (props) => {
  const { collectionName, setCollectionName } = props;
  const [projects, setProjects] = useState<Project[] | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const projectsResponse = await databaseService.getProjects();
      console.log(projectsResponse);
      setProjects(projectsResponse);
    })();
  }, []);

  const handleProjectSelect = (event: { target: { value: string } }) => {
    setCollectionName(event.target.value);
  };

  return (
    <FormControl>
      <InputLabel id="project-select-label">Project</InputLabel>
      <Select
        labelId="project-select-label"
        id="project-select"
        value={collectionName ?? undefined}
        label="File"
        onChange={handleProjectSelect}
        disabled={!projects?.length}
        style={{ width: '300px' }}
      >
        {projects &&
          Array.isArray(projects) &&
          projects?.map((project, index) => (
            <MenuItem key={`${project._id}-${index}`} value={project._id}>
              {project.project_name} ({project.file_name})
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export { ProjectSelect };
