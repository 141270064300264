import ColumnLineageChartControls from './column-lineage-chart-controls';
import ColumnLineageChart from './column-lineage-chart';
import { createContext, useState } from 'react';
import { HeadingLg } from '../../shared/styles';
import { ColumnLineage } from '../../model/ColumnLineage';

export interface IChartContext {
  lineage_data: ColumnLineage;
  lineageGenerated: (lineage_data: ColumnLineage) => Promise<void>;
}

export const ChartContext = createContext({});

const ColumnLineagePage = () => {
  const initial_lineage_data: ColumnLineage = {
    defaultNodes: [],
    defaultEdges: [],
  };

  const [lineage_data, setMarkdown] = useState(initial_lineage_data);
  const [showChart, setShowChart] = useState(false);
  const lineageGenerated = async (lineage_data: ColumnLineage) => {
    setMarkdown(lineage_data);
    await loadChart();
  };

  const loadChart = async () => {
    setShowChart(false);
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        setShowChart(true);
        resolve();
      });
    });
  };

  const initContext: IChartContext = {
    lineage_data,
    lineageGenerated,
  };

  return (
    <>
      <HeadingLg>Column Lineage</HeadingLg>
      <ChartContext.Provider value={initContext}>
        <ColumnLineageChartControls />
        {!!showChart && <ColumnLineageChart />}
      </ChartContext.Provider>
    </>
  );
};

export default ColumnLineagePage;
