import {
  schemas,
  databases,
  tables,
  markdown,
  fileParseOutcomes,
  parsedScript,
  columns,
  projects,
  columnLineage,
} from '../mock-data';
import { Entity } from '@/model/Entity';
import { getConfigValue } from '../Utils';
import ApiClient from './api-client';
import { orderBy } from 'lodash';
import { AxiosError } from 'axios';
import { FileParseOutcome } from '@/model/FileParseOutcome';
import { ParsedScript } from '@/model/ParsedScript';
import { Project } from '@/model/Project';
import { ColumnLineage } from '../model/ColumnLineage';

class DatabaseService extends ApiClient {
  constructor() {
    super(getConfigValue('REACT_APP_PARSE_API_URL'));
    this.test = JSON.parse(getConfigValue('REACT_APP_TEST'));
  }

  private readonly test: boolean;

  async getCatalogs(): Promise<Entity[]> {
    const catalogId = 0;
    const catalogs = this.test
      ? databases
      : await this.get(`/catalogs/${catalogId}`).catch((e: AxiosError) =>
          console.error(e)
        );
    return orderBy(catalogs, ['id']);
  }

  async getSchemas(catalogId: number): Promise<Entity[]> {
    const schemaList = this.test
      ? schemas
      : await this.get(`/schemas/${catalogId}`).catch((e: AxiosError) =>
          console.error(e)
        );
    return orderBy(schemaList, ['id']);
  }

  async getTables(schemaId: number): Promise<Entity[]> {
    const tableList = this.test
      ? tables
      : await this.get(`/tables/${schemaId}`).catch((e: AxiosError) =>
          console.error(e)
        );
    return orderBy(tableList, ['id']);
  }

  async getColumns(tableId: number): Promise<Entity[]> {
    const columnList = this.test
      ? columns
      : await this.get(`/columns/${tableId}`).catch((e: AxiosError) =>
          console.error(e)
        );
    return orderBy(columnList, ['id']);
  }

  async getMarkdown(tableId: number): Promise<string> {
    return this.test
      ? markdown
      : await this.get(`/lineage/${tableId}`).catch((e: AxiosError) =>
          console.error(e)
        );
  }

  async getColumnLineage(columnId: number): Promise<ColumnLineage> {
    return this.test
      ? columnLineage
      : await this.get(`/columnLineage/${columnId}`).catch((e: AxiosError) =>
          console.error(e)
        );
  }

  async getFilesParseOutcome(
    collectionName: string
  ): Promise<FileParseOutcome[]> {
    return this.test
      ? fileParseOutcomes
      : await this.get(`/filesParseOutcome/${collectionName}`).catch(
          (e: AxiosError) => console.error(e)
        );
  }

  async getParsedScript(
    collectionName: string,
    objectId: string
  ): Promise<ParsedScript> {
    return this.test
      ? parsedScript
      : await this.get(`/parsedScript/${collectionName}/${objectId}`).catch(
          (e: AxiosError) => console.error(e)
        );
  }

  async getProjects(): Promise<Project[]> {
    return this.test
      ? projects
      : await this.get(`/projects`).catch((e: AxiosError) => console.error(e));
  }
}

const service = new DatabaseService();
export default service;
