import { Entity } from '@/model/Entity';
import { FileParseOutcome } from '@/model/FileParseOutcome';
import { ParsedScript } from '@/model/ParsedScript';
import { Project } from '@/model/Project';
import { ColumnLineage } from './model/ColumnLineage';

const databases: Entity[] = [
  {
    id: 1,
    name: 'database 1',
  },
  {
    id: 2,
    name: 'database 2',
  },
];

const schemas: Entity[] = [
  {
    id: 1,
    name: 'schema 1',
  },
  {
    id: 2,
    name: 'schema 2',
  },
];

const tables: Entity[] = [
  {
    id: 1,
    name: 'table 1',
  },
  {
    id: 2,
    name: 'table 2',
  },
  {
    id: 3,
    name: 'table 3',
  },
];

const columns: Entity[] = [
  {
    id: 1,
    name: 'column 1',
  },
  {
    id: 2,
    name: 'column 2',
  },
  {
    id: 3,
    name: 'column 3',
  },
];

const markdown = `
    flowchart LR

    classDef NemoDatabase fill:#DDD;font-size:large
    classDef NemoSchema fill:#BBB
    classDef NemoTable fill:#FFF
      style 347 fill:#DDD;font-size:large
      subgraph 347[Database: TransformDiagnostic]
      
        style 367 fill:#BBB
        subgraph 367[Schema: Dimension]
        
          n569[["Calendar"]]:::NemoTable
              end
      end
  `;

const fileParseOutcomes: FileParseOutcome[] = [
  {
    _id: 'id_1',
    filename: 'test1.sql',
    file_path: '',
    parse_status: 1,
    neo_writer_stats: {
      elapsed: { prepare_ms: 12, write_ms: 130 },
      completed_at: '2024-08-09 15:55:19.529577',
    },
  },
  {
    _id: 'id_2',
    filename: 'test2.sql',
    file_path: '',
    parse_status: 0,
  },
];

const parsedScript: ParsedScript = {
  _id: 'id_1',
  filename: 'test1.sql',
  raw: `
    USE [TestDatabase]<br/>
    GO<br/>
    SET ANSI_NULLS ON<br/>
    GO<br/>
    SET QUOTED_IDENTIFIER ON<br/>
    GO<br/>
    CREATE TABLE [dbo].[TestTable](<br/>
      [ID] [int] IDENTITY(1,1) NOT NULL,<br/>
      [UserID] [int] NOT NULL,<br/>
      [Created] [date] NOT NULL,<br/>
      [TypeID] [int] NOT NULL,<br/>
    CONSTRAINT [PK_Test] PRIMARY KEY CLUSTERED <br/>
    (<br/>
      [ID] ASC<br/>
    )WITH (PAD_INDEX = OFF, STATISTICS_NORECOMPUTE = OFF, IGNORE_DUP_KEY = OFF, ALLOW_ROW_LOCKS = ON, ALLOW_PAGE_LOCKS = ON) ON [PRIMARY]<br/>
    ) ON [PRIMARY]<br/>
    GO<br/>
    ALTER TABLE [dbo].[TestTable] ADD  CONSTRAINT [DF_Test_Created]  DEFAULT (getdate()) FOR [Created]<br/>
    GO
  `,
  parsed: {
    type: 'Script',
    statements: [
      {
        type: 'Ddl',
        action: 'CREATE',
        object_defn: {
          type: 'DatabaseDefn',
          name: 'CatalogA',
        },
      },
    ],
  },
};

const columnLineage: ColumnLineage = {
  defaultNodes: [
    {
      id: '1',
      position: { x: 0, y: 0 },
      type: 'databaseSchema',
      data: {
        label: 'Products',
        schema: [
          { title: 'id', type: 'uuid' },
          { title: 'name', type: 'varchar' },
          { title: 'description', type: 'varchar' },
          { title: 'warehouse_id', type: 'uuid' },
          { title: 'supplier_id', type: 'uuid' },
          { title: 'price', type: 'money' },
          { title: 'quantity', type: 'int4' },
        ],
      },
    },
    {
      id: '2',
      position: { x: 350, y: -100 },
      type: 'databaseSchema',
      data: {
        label: 'Warehouses',
        schema: [
          { title: 'id', type: 'uuid' },
          { title: 'name', type: 'varchar' },
          { title: 'address', type: 'varchar' },
          { title: 'capacity', type: 'int4' },
        ],
      },
    },
    {
      id: '3',
      position: { x: 350, y: 200 },
      type: 'databaseSchema',
      data: {
        label: 'Suppliers',
        schema: [
          { title: 'id', type: 'uuid' },
          { title: 'name', type: 'varchar' },
          { title: 'description', type: 'varchar' },
          { title: 'country', type: 'varchar' },
        ],
      },
    },
  ],
  defaultEdges: [
    {
      id: 'products-warehouses',
      source: '1',
      target: '2',
      sourceHandle: 'warehouse_id',
      targetHandle: 'id',
    },
    {
      id: 'products-suppliers',
      source: '1',
      target: '3',
      sourceHandle: 'supplier_id',
      targetHandle: 'id',
    },
  ],
};

const projects: Project[] = [
  {
    _id: '123',
    project_name: 'project1',
    database: 'db1',
    default_schema: 'dbo',
    script_count: 123,
    script_count_success: 120,
    script_count_failed: 3,
    file_name: 'dw.zip',
    sql_cmd_vars: [{ key: 'sourcedb', value: 'devdb1' }],
  },
  {
    _id: '456',
    project_name: 'project2',
    database: 'db1',
    default_schema: 'dbo',
    script_count: 55,
    script_count_success: 25,
    script_count_failed: 30,
    file_name: 'dw.zip',
    sql_cmd_vars: [{ key: 'sourcedb', value: 'devdb1' }],
  },
];

export {
  databases,
  schemas,
  tables,
  columns,
  markdown,
  fileParseOutcomes,
  parsedScript,
  columnLineage,
  projects,
};
